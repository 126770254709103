var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.paginando)?_c('Loading',{attrs:{"full-page":true,"color":_vm.$vuetify.theme.themes.light.primary}}):_vm._e(),_c('v-row',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"d-flex justify-start align-center"},[(_vm.cotizadorHotels.destino === null || _vm.cotizadorHotels.destino === '')?_c('v-autocomplete',{staticClass:"pr-2",attrs:{"items":_vm.itemsDestinos,"search-input":_vm.searchDestinos,"label":_vm.$t('lbl.hotelDestinos'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":"","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchDestinos=$event},"update:search-input":function($event){_vm.searchDestinos=$event},"click:clear":_vm.clearDestinos,"change":_vm.changeDestinos},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultDestinos ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('lbl.hotelDestinos'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,3920913981),model:{value:(_vm.cotizadorHotels.destino),callback:function ($$v) {_vm.$set(_vm.cotizadorHotels, "destino", $$v)},expression:"cotizadorHotels.destino"}}):_c('v-text-field',{ref:"autocomplete",attrs:{"label":_vm.$t('lbl.hotelDestinos'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearDestinos,"input":_vm.inputDestinos},model:{value:(_vm.cotizadorHotels.destino.name),callback:function ($$v) {_vm.$set(_vm.cotizadorHotels.destino, "name", $$v)},expression:"cotizadorHotels.destino.name"}})],1)]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.dateIn'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedFromDateFormatted),callback:function ($$v) {_vm.computedFromDateFormatted=$$v},expression:"computedFromDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minDate.toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFrom = false
            _vm.activeToDate()}},model:{value:(_vm.cotizadorHotels.dateIn),callback:function ($$v) {_vm.$set(_vm.cotizadorHotels, "dateIn", $$v)},expression:"cotizadorHotels.dateIn"}})],1)],1),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"disabled":!_vm.cotizadorHotels.dateIn,"label":_vm.$t('lbl.dateOut'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedToDateFormatted),callback:function ($$v) {_vm.computedToDateFormatted=$$v},expression:"computedToDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","show-current":false,"min":_vm.cotizadorHotels.dateIn
              ? _vm.$moment(_vm.cotizadorHotels.dateIn)
                .add(1, 'day')
                .toDate()
                .toISOString()
                .substr(0, 10)
              : _vm.minDate},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.cotizadorHotels.dateOut),callback:function ($$v) {_vm.$set(_vm.cotizadorHotels, "dateOut", $$v)},expression:"cotizadorHotels.dateOut"}})],1)],1),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"font-weight-medium",staticStyle:{"text-transform":"capitalize","justify-content":"left"},attrs:{"align":"left","block":"","outlined":"","color":"#8a8d93"},on:{"click":_vm.changeOcupation}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-human-male-girl ")]),_vm._v(" "+_vm._s(("" + (_vm.cotizadorHotels.ocupation ? (_vm.cantViajeros + " " + (_vm.$t('lbl.travels')) + ", " + (_vm.cotizadorHotels.ocupation.length) + " " + (_vm.$t('lbl.roomD'))) : _vm.$t('lbl.ocupation'))))+" ")],1),(_vm.showOcupationRoomHotel)?_c('span',[_c('v-container',{staticClass:"container-chat col-lg-3 col-md-3 col-sm-3 col-xs-3 pl-7",attrs:{"fluid":"","pa-0":""}},[_c('HotelOcupation',{attrs:{"config-search":_vm.configSearch,"max-age":_vm.max_age}})],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-spacer'),(!_vm.loadingSearch && _vm.cotizadorHotelsResultPaginate.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-btn',{attrs:{"color":"primary","width":"100%","outlined":""},on:{"click":function($event){_vm.updateShowMap(!_vm.showMapHotels)
              _vm.scrollInto('showMap')}}},[_c('v-icon',[_vm._v("mdi-map-marker")]),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(!_vm.showMapHotels ? _vm.$t('lbl.show') : _vm.$t('lbl.hidden')))])],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":!_vm.cotizadorHotels.destino &&
              !_vm.cotizadorHotels.dateIn &&
              !_vm.cotizadorHotels.dateOut &&
              _vm.cotizadorHotels.ocupation.length === 1 &&
              !_vm.cotizadorHotels.proveedor_id &&
              !_vm.cotizadorHotels.categoria_id &&
              !_vm.cotizadorHotels.tipo_destino_id
              ? '12'
              : '6'}},[_c('v-btn',{attrs:{"color":"primary","width":"100%","loading":_vm.loadingSearch},on:{"click":function($event){_vm.updateShowMap(false)
              _vm.resetPagination()
              _vm.search()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.search'))+" ")])],1),(
            _vm.cotizadorHotels.destino ||
              _vm.cotizadorHotels.dateIn ||
              _vm.cotizadorHotels.dateOut ||
              _vm.cotizadorHotels.ocupation.length > 1 ||
              _vm.cotizadorHotels.proveedor_id ||
              _vm.cotizadorHotels.categoria_id.length > 0 ||
              _vm.cotizadorHotels.tipo_destino_id
          )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"error","width":"100%","disabled":_vm.loadingSearch},on:{"click":_vm.clearForm}},[_vm._v(" "+_vm._s(_vm.$t('btn.clear'))+" ")])],1):_vm._e()],1)],1)],1),(!_vm.loadingSearch && _vm.cotizadorHotelsResult.length > 0)?[_c('DisponibilidadHotels',{attrs:{"id":"showMap","user":_vm.user}})]:(_vm.oneSearch && _vm.cotizadorHotelsResult.length === 0)?_c('NoResultsHotels'):_vm._e(),(_vm.searchingHotel)?_c('v-row',[(!_vm.isLoadingSkeleton)?_vm._l((_vm.hotelsSkeleton),function(hotel,indH){return _c('v-col',{key:indH,attrs:{"cols":"12"}},[_c('SkeletonResultsHotels',{attrs:{"hotel":hotel,"days-search":_vm.daysSearch}})],1)}):_vm._e()],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }